export function getWindowUrlSearch()
{
    // Return search due to present?
    if(window.location.search)
        return window.location.search;

    // Break search from hash. We have a hash url due to vue router.
    let splits = window.location.hash.split('?');

    return splits.length > 1 ? '?' + splits[1] : '';
}