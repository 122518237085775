import {getWindowUrlSearch} from "@/urlHelpers";

class SCORMAPI {
    constructor() {
        this.storage = {};
    }
	Initialize() {
        return 'true';
	}

	Terminate() {
        try {
            this.win.close();
        } catch(e) {
            //probably closed already
        }
	}

	GetValue(key) {
        return this.storage[key] || '';
	}

	SetValue(key,val) {
        return  this.storage[key] = val;
	}

	Commit() {
        return 'true';
	}

	GetLastError() {
        return 0;
	}

	GetErrorString() {
	}

	GetDiagnostic() {
	}
    LMSInitialize() { return this.Initialize(); }
    LMSFinish() { return this.Terminate(); }
    LMSGetValue(key) { return this.GetValue(key); }
	LMSSetValue(key,val) { return this.SetValue(key,val); }
	LMSCommit() { return this.Commit(); }
	LMSGetLastError() { return this.GetLastError(); }
	LMSGetErrorString() { return this.GetErrorString(); }
	LMSGetDiagnostic() { return this.GetDiagnostic(); }
}

const addStyle = () => {
    const style = document.createElement('style');
    style.innerHTML = `
    html, body, iframe {
    width: 100%;
    height: 100%;
    }

    iframe {
        border: 0;
    }
    `;
    document.body.appendChild(style);
}


export default (zip = null) => {
    const api = new SCORMAPI();
    window.API = api;
    window.API_1484_11 = api;

    addStyle();

    const iframe = document.createElement('iframe');
    if(zip === null) {
        const code = getWindowUrlSearch().match(/preview_scorm=([^&]+)/)[1]
        zip = '/static/'+code+'.zip';
    }
    const url = '/api/programs/index'+zip;
    iframe.src = url;
    document.body.appendChild(iframe);
}
