import courseProgressInfo from './course-progress-info.js';
import stats from './stats.js';
import axios from 'axios';
import {getWindowUrlSearch} from "@/urlHelpers";

const find = (node, key) => {
    if(node.proKey === key) { return node; }
    const { childs } = node;
    if(childs) {
        for(let i=0; i<childs.length; i++) {
            const result = find(childs[i], key);
            if(result) {
                return result;
            }
        }
    }
}

class SCORMAPI {
    constructor(courseId) {
        this.courseId = courseId;
        this.storage = {};
    }

    getCourseId() {
        return this.courseId;
    }
	Initialize() {
        this.initTime = new Date();
        this.SetValue('started', 1);
        stats.add('course.init', { course:this.getCourseId() });
        return 'true';
	}

	Terminate() {
        try {
            this.win.close();
        } catch(e) {
            //probably closed already
        }
        const now = new Date();
        const secondsElapsed = Math.round( (now.getTime() - this.initTime.getTime())/1000 );
        stats.add('course.terminate', { course:this.getCourseId(), sessionTime:secondsElapsed });
	}

	GetValue(key) {
        return this.storage[key] || '';
	}

	SetValue(key,val) {
        if(this.storage[key] !== val) {
            this.storage[key] = val;
            const progress = courseProgressInfo(this.storage);
            if(progress === 2 && !this.storage.werkFinished) {
                this.storage.werkFinished = true;
                stats.add('course.finish', { course:this.getCourseId() });
            }
            if(progress !== this.progress) {
                this.progress = progress;
                this.outputProgress();
            }
        }
        return 'true';
	}

	Commit() {
        return 'true';
	}

	GetLastError() {
        return 0;
	}

	GetErrorString() {
	}

	GetDiagnostic() {
	}
    LMSInitialize() { return this.Initialize(); }
    LMSFinish() { return this.Terminate(); }
    LMSGetValue(key) { return this.GetValue(key); }
	LMSSetValue(key,val) { return this.SetValue(key,val); }
	LMSCommit() { return this.Commit(); }
	LMSGetLastError() { return this.GetLastError(); }
	LMSGetErrorString() { return this.GetErrorString(); }
	LMSGetDiagnostic() { return this.GetDiagnostic(); }
}

const addStyle = () => {
    const style = document.createElement('style');
    style.innerHTML = `
    html, body, iframe {
    width: 100%;
    height: 100%;
    }

    iframe {
        border: 0;
    }
    `;
    document.body.appendChild(style);
}

export default async() => {
    stats.source = 'professional';
    const tree = (await axios.get('/programs/tree')).data;
    const code = getWindowUrlSearch().match(/professional=([^&]+)/)[1]
    const node = find(tree, code);
    if(node) {
        let zip = node.scorm.file;
        stats.add('visit', {});
        const api = new SCORMAPI(node.id);
        window.API = api;
        window.API_1484_11 = api;

        addStyle();

        const iframe = document.createElement('iframe');
        if(zip === null) {
            const code = getWindowUrlSearch().match(/preview_scorm=([^&]+)/)[1]
            zip = '/static/'+code+'.zip';
        }
        const url = '/api/programs/index'+zip;
        iframe.src = url;
        document.body.appendChild(iframe);
    }

}
