<template>
    <div id="app" :class="{ locked:popover, rtl }">
        <template v-if="normalFlow">
            <component :is="popover" />
            <div :aria-hidden="popover != null">
                <top />
                <center class="normal" />
                <bottom />
            </div>
        </template>
        <center v-else-if="programsLoaded" />
	</div>
</template>
<script>

import Bottom from './Bottom'
import Center from './Center'

import Top from './Top'

import { mapGetters } from 'vuex';
import stats from './stats';

import Register from './Register';
import Forgot from './Forgot';
import Login from './Login';
import Profile from './Profile';
import InstructionVideo from './InstructionVideo';
import ContinueCourse from './ContinueCourse';

import axios from 'axios';
import langs from './langs';
import surveyHandler from './survey-handler.js';
import {getWindowUrlSearch} from "@/urlHelpers";
export default {
	name: 'app',
	components: {
        Bottom,
        Center,
        Top,
        Register,
        Forgot,
        Login,
        Profile,
        ContinueCourse,
        InstructionVideo,
	},

    data() {
        return {
            programsLoaded: false,
        };
    },

	async created() {
        await this.handleOefenen();
        await this.handleConnect();
        stats.add('visit', {});
        
        window.addEventListener('resize', this.onResize);
        this.onResize();

		window.addEventListener('hashchange', ()=> {
			this.hashchange();
		});
        this.hashchange();
        surveyHandler();

        this.testProgramsLoaded();
	},

    watch: {
        programRoot() {
            this.testProgramsLoaded();
        },
    },

	methods: {
        testProgramsLoaded() {
            if(!this.normalFlow && !this.programsLoaded) {
                this.hashchange();
                this.programsLoaded = true;
            }
        },

        async handleOefenen() {

            if(getWindowUrlSearch().includes('?ProgramKey')) {
                this.$store.commit('setOefenen', getWindowUrlSearch());
                window.history.replaceState({}, document.title, '/');
            }

            if(this.oefenen) {
                const confirmCredentials = [
                    'https://oefenen.nl/api.php?Action=ConfirmCredentials&',
                    this.oefenen.substr(1),
                ].join('');
                await axios.get(confirmCredentials)
                stats.source = 'oefenen';
            }
        },
        async handleConnect() {
            const match = getWindowUrlSearch().match(/connect=([^&\/]+)/);
            if(match) {
                const obj = JSON.parse(atob(match[1]));
                this.$store.commit('setConnect', obj);
                // window.history.replaceState({}, document.title, '/');
            }
        },
        onResize() {
            const phoneView = window.innerWidth < 801;
            this.$store.commit('setPhoneView', phoneView);
            this.$store.commit('setWidth', window.innerWidth);
        },

		async hashchange() {
			let hash = window.location.hash;
            const parts = hash.split('/');
            const last = parts[ parts.length-1 ];

            const { popovers } = this;
            if(popovers[last] !== undefined) {
                parts.pop();
                this.$store.commit('setPopover', popovers[last]);
                window.location.hash = parts.join('/');
                return;
            }
			if(hash.length <= 2) {
                if(!this.oefenen) {
                    window.location.hash = '/programmas';
                } else {
                    const { oefenen } = this;
                    const moduleMatch = oefenen.match(/ModuleID=([^&]+)/);
                    const programKeyMatch = oefenen.match(/ProgramKey=([^&]+)/);

                    let obj = null;
                    if(moduleMatch) {
                        const moduleId = moduleMatch[1];
                        obj = this.modulesMap['WERK-'+moduleId];
                    } else if(programKeyMatch) {
                        const programKey = programKeyMatch[1];
                        obj = this.modulesMap[programKey];
                    }
                    if(obj) {
                        if(obj.lang) {
                            const langCode = langs.find(l => l.name === obj.lang).code;
                            await this.$store.dispatch('setLanguage', langCode);
                        }
                        window.location.hash = obj.path;
                    }

                }
                return;

			}
			this.$store.commit('setHash', hash);
            window.scrollTo(0,0);
		},
	},

	computed: {
		...mapGetters([
			'hash',
			'popover',
			'popovers',
			'user',
			'language',
            'programRoot',
            'oefenen',
            'connect',
            'modulesMap',
		]),

        normalFlow() {
            if(this.oefenen) { return false; }
            if(this.connect) { return false; }
            return true;
        },

        rtl() {
            return this.language === 'ar';
        },
	}


}
</script>

<style lang="scss">

@import "colors.scss";
@import "variables.scss";
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,900');

html, body {
	margin: 0;

}

.button {
    padding: 8px 16px;
    border: 0 !important;
    background: $purple;
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

a {
    color: $darkgray;
}


.boxed {
	max-width: 1024px;
	margin: 0 auto;
	position: relative;
	box-sizing: border-box;
}

.padded {
	padding: 40px 10px;
}


@media (max-width: $phone) {
    .boxed.padded {
        padding: 10px;
    }
}

h1 {
	margin: 0;
	font-size: 24px;
	color: $purple;
}

h2 {
	margin: 0;
	font-size: 18px;
}

#app {
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #574D56;

}

.error {
    background: #b20;
    color: white;
    padding: 10px;
}

.bottom-back {
    height: 100px;
}

.link {
    cursor: pointer;
    text-decoration: underline;
}

.rtl .ProgramTile {
    h3, p {
        direction: rtl;
    }
}

button {
    font-family: inherit;
    border: 0;
}

@media (max-width: $phone) {
    h1 {
        margin-top: 10px;
    }
    .bottom-back {
        display: flex;
        justify-content: center;
        padding-top: 30px;
        height: 140px;
    }
    .BackButton {
        position: inherit;
    }
}
</style>
